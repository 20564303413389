import { message } from 'antd'
import React, { useEffect, useState } from 'react'

import Icon from '../components/Icon'

import { useRender } from './useRender'
import { useQuizMetrics } from './useQuizMetrics'
import { useElementsHandler } from './useElementsHandler'
import { usePlatformSpecific } from './usePlatformSpecific'

import {
  getSlideTimeShifts,
  isNotQuestionSubObjectOnlyQuestions,
  isSlideInteractive,
  preloadMediaElement,
  secondsToHMS,
} from '../utils/helpers'

import { SUBMENU_KEY_DOWNLOAD, SUBMENU_KEY_RATE, PLAY_RATES, DEFAULT_VOLUME } from '../constants'

/**
 * @var {boolean}: is user can search in video or not
 */
let allowNavigation = true

let actualSlideId = undefined

export const usePlayerState = (props) => {
  const { video, videoRef, wrapperRef, progressDimsRef, videoId, search } = props

  // video player state
  const [muted, setMuted] = useState(false)
  const [paused, setPaused] = useState(true)
  const [volume, setVolume] = useState(DEFAULT_VOLUME)
  const [currTime, setCurrentTime] = useState(0)
  const [isSubtitlesVisible, setIsSubtitlesVisible] = useState(true)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isChapterMenuOpen, setIsChapterMenuOpen] = useState(false)
  const [videoEl, setVideoEl] = useState(null)
  const setCurrTime = (num, label) => {
    const time = +num.toFixed(3)
    // console.log(
    //   `videoEl.currTime: ${videoEl?.currentTime}; videoRef.current.currentTime: ${videoRef.current.currentTime}`,
    // )
    // console.log(`SET CURR TIME FROM ${currTime} TO ${time} TRIGGER: ${label ?? '??'}`)
    setCurrentTime(time)
  }
  const [playbackRate, setPlaybackRate] = useState('Normal')
  const [openSubmenu, setOpenSubmenu] = useState(null)
  // hidden | hover | visible
  const [controlsVisible, setControlsVisible] = useState('visible')
  const [currentTimePopup, setCurrentTimePopup] = useState(null)
  const [showLoader, setShowLoader] = useState(true)
  // state for interactive elements
  const [marksRender, setMarksRender] = useState({})
  const [marksTime, setMarksTime] = useState([])
  const [renderElements, setRenderElements] = useState([])
  const [playerBoundingRect, setPlayerBoundingRect] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(null)
  const [navigationSlideId, setNavigationSlideId] = useState(null)
  const [mainStyles, setMainStyles] = useState(null)
  const [clickedElementId, setClickedElementId] = useState(null)
  const [isPreloadImages, setIsPreloadImages] = useState(true)

  // flag which shows is video interactive or not
  const [isInteractive, setIsInteractive] = useState(false)

  // if user able to play video or not
  const [slideClicked, setSlideClicked] = useState('')

  const [submittedAnswers, setSubmittedAnswers] = useState([])

  const [messageApi, messageContextHolder] = message.useMessage({
    getContainer: () => document.getElementById('interactive-player'),
    prefixCls: 'app-message',
  })

  const [isPlayDisabled, setIsPlayDisabled] = useState(false)
  const [isClickDisabled, setIsClickDisabled] = useState(false)

  const [isVideoFinished, setIsVideoFinished] = useState(false)

  const isIOSFullScreen = search.get('noopen')

  const { platform, browser } = usePlatformSpecific({ videoRef, wrapperRef, isIOSFullScreen })

  const {
    setClick,
    stopTimer,
    startTimer,
    setQuizSlides,
    updateMetrics,
    setAnswerClick,
    collectWhenFinished,
    resetDurationWatched,
  } = useQuizMetrics({
    paused,
    currentSlide,
    actualSlideId,
    video,
    videoId,
    isVideoFinished,
    setIsVideoFinished,
  })

  const { setNeedRerenderAnswers, setCheckboxTemplate } = useElementsHandler({
    currentSlide,
    renderElements,
    setNavigationSlideId,
    setRenderElements,
    clickedElementId,
    setClickedElementId,
    setShowLoader,
    messageApi,
    videoEl,
    setPaused,
    setSlideClicked,
    submittedAnswers,
    setSubmittedAnswers,
    setIsPlayDisabled,
    setClick,
    setAnswerClick,
    updateMetrics,
    resetDurationWatched,
  })

  const { getAnimations } = useRender({
    playerBoundingRect,
    currentSlide,
    setRenderElements,
    setClickedElementId,
    setNeedRerenderAnswers,
  })

  // as marks contain when slide ends and not when it starts so take previous slide time
  const chapterMenuItems = [
    { key: 'title', label: <div>Chapters</div> },
    ...marksTime
      .filter((m) => m.chapter)
      .map((m, index, filteredMarks) => ({
        key: index > 0 ? filteredMarks[index - 1].nextSlideBeginTime : 0,
        label: (
          <div>
            <span>{secondsToHMS(index > 0 ? filteredMarks[index - 1].time : 0)}</span>
            <span>{m.chapter}</span>
          </div>
        ),
      })),
  ]

  const menuItemsHandler = ({ key }) => {
    const [type, value] = key.split(',')
    if (type === 'rate') {
      videoEl.playbackRate = value === 'Normal' ? 1 : Number(value)
      setPlaybackRate(value === 'Normal' ? 1 : Number(value))
    } else if (type === 'subtitles') {
      setIsSubtitlesVisible((prev) => !prev)
    } else if (type === 'download') {
      const link = document.createElement('a')
      link.href = video[value]
      link.click()
    }
    setIsMenuOpen(false)
  }

  const chapterMenuItemsHandler = (chapter) => {
    const time = Number(chapter.key)
    setIsChapterMenuOpen(false)
    if (!allowNavigation) {
      showNavigationNotification()
      return
    }
    setIsPlayDisabled(false)
    // do not show previous slide stuff
    setCurrTime(time, 'chapterMenuItemsHandler')
    videoEl.currentTime = time
  }

  // handle click on control panel button
  const handleControlsVisibility = () => {
    if (controlsVisible === 'hidden') {
      if (paused && !getInteractiveMarkerIfCloseToCurrTime()) {
        // on safari set it to visible as some times may lead to blinking quiz
        // set hover when user continue to play video
        setControlsVisible('hover')
      } else {
        setControlsVisible('visible')
      }
    } else {
      setControlsVisible('hidden')
    }
  }

  // play icon click or just player click
  const handlePlayPause = ({ forcePlay = false }) => {
    if (!videoEl?.src || isPlayDisabled) {
      return
    }
    const isPlaying =
      videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > videoEl.HAVE_CURRENT_DATA
    if (!isPlaying || forcePlay) {
      const marker = getInteractiveMarkerIfCloseToCurrTime()
      if (!isPlaying && !forcePlay && marker) {
        // Case when we stopped on interactive marker and need to continue play from pause, not from navigating (forcePlay: false)
        // We need to jump to the beginning of the next slide to stop looping. Time looping is mostly reproduced when playback speed is too low (0.25)
        setTimeToNextSlideBeginTime(marker)
      }
      startTimer()
      videoEl.play()
      setPaused(false)
      setIsClickDisabled(false)
      if (isVideoFinished) {
        setIsVideoFinished(false)
      }
      // set for hover when user play video
      setControlsVisible('hover')
    } else {
      videoEl.pause()
      setPaused(true)
      stopTimer()
      setControlsVisible('visible')
    }
  }

  const handleVolumeChange = (value) => {
    if (value === 0) {
      videoEl.muted = true
      setMuted(true)
    } else {
      videoEl.muted = false
      setMuted(false)
    }

    videoEl.volume = value / 100
    setVolume(value)
  }

  const handleVolumeMute = () => {
    if (videoEl.muted) {
      videoEl.muted = false
      setMuted(false)

      if (videoEl.volume === 0) {
        videoEl.volume = DEFAULT_VOLUME / 100
        setVolume(DEFAULT_VOLUME)
      }
    } else {
      videoEl.muted = true
      setMuted(true)
    }
  }

  const handleChangeTime = (value) => {
    if (!videoEl.src) return
    if (!allowNavigation) {
      showNavigationNotification()
      return
    }
    if (isVideoFinished) {
      setIsVideoFinished(false)
    }
    setCurrTime(+((videoEl.duration / 100) * value).toFixed(3), 'handleChangeTime')
    videoEl.currentTime = (videoEl.duration / 100) * value
    const slides = video.slides
    let totalDuration = 0
    slides.forEach((slide, index) => {
      totalDuration = +slide.duration
      if (totalDuration >= videoEl.currTime) {
        setCurrentSlide(slides[index > 0 ? index - 1 : index])
        return
      }
    })
  }

  const setTimeToNextSlideBeginTime = (marker) => {
    videoEl.currentTime = marker.nextSlideBeginTime
    setCurrTime(marker.nextSlideBeginTime, 'handlePlayPause - interactiveMarker end time')
  }

  const setTimeToNextMark = (setTimeToSlideEnd) => {
    // console.log('moveToNext')
    let currentMarker = marksTime[0]
    if (currentMarker === undefined) return
    if (isVideoFinished) {
      setIsVideoFinished(false)
    }
    let time
    for (const m of marksTime) {
      time = currentMarker.slideEndTime
      if (time > currTime) break
      currentMarker = m
    }
    time = setTimeToSlideEnd ? currentMarker.slideEndTime : currentMarker.nextSlideBeginTime
    videoEl.currentTime = time
    setCurrTime(time, 'moveToNextMark')
  }

  const setTimeToPrevMark = (setTimeToSlideEnd) => {
    // console.log('moveToPrev')
    let currentMarker = marksTime[marksTime.length - 1]
    if (currentMarker.time === undefined) return
    if (isVideoFinished) {
      setIsVideoFinished(false)
    }
    let time
    for (const m of marksTime.slice().reverse()) {
      time = setTimeToSlideEnd ? currentMarker.slideEndTime : currentMarker.nextSlideBeginTime
      if (time < currTime) break
      currentMarker = m
    }
    time = setTimeToSlideEnd ? currentMarker.slideEndTime : currentMarker.nextSlideBeginTime
    videoEl.currentTime = time
    setCurrTime(time, 'moveToPrevMark')
  }

  const handleFullScreen = () => {
    // If this is Iphone and not already needed page open new tab with full width/height player
    if (platform === 'ios') {
      /**
       * Check whether we already on needed page or not
       * other methods such as check for location.href or /player/.test does not work
       */
      if (isIOSFullScreen) {
        return
      }
      // if(sea)
      const a = document.createElement('a')
      const url = process.env.REACT_APP_URL.split('//')
      a.target = '_blank'
      // open new tab with param that shows us that next time new tab wont be opened
      a.href = `https://${url[1]}/${videoId}?noopen=true`
      handlePlayPause({})
      a.click()
      return
    }
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      if (wrapperRef.current.requestFullscreen) {
        wrapperRef.current.requestFullscreen()
      } else if (wrapperRef.current.webkitRequestFullscreen) {
        wrapperRef.current.webkitRequestFullscreen()
      } else if (wrapperRef.current.msRequestFullScreen) {
        wrapperRef.current.msRequestFullScreen()
      }
    }
  }

  const setSlideObjectsByMarker = (marker) => {
    const slide = video.slides.find((s) => s.id === marker.slideId)
    stopTimer(true)
    setCurrentSlide(slide)
    return slide
  }

  const getSimpleMarkerIfCloseToCurrTime = () => {
    return marksTime.find((m) => m.type === 'slide' && -0.17 <= currTime - m.time && currTime - m.time <= 0.15)
  }

  const getInteractiveMarkerIfCloseToCurrTime = () => {
    // NOTE: tune is voice stops before it ends 'cause of interactive elements
    const negativeSpeed = playbackRate > 1 ? -0.4 : -0.2
    const marker = marksTime.find((m) => {
      const isInteractive = m.type === 'interactive'
      const timeToMarker = currTime - m.slideEndTime
      const isTimeToMarkerGTENegativeSpeed = negativeSpeed <= timeToMarker
      const isTimeToMarkerLessThanMinimal = timeToMarker <= 0.1
      const isTimeToMarkerBetweenNegativeSpeedAndMinimal =
        isTimeToMarkerGTENegativeSpeed && isTimeToMarkerLessThanMinimal
      const isMarkerFound = isInteractive && isTimeToMarkerBetweenNegativeSpeedAndMinimal
      // console.log({
      //   timeToMarker,
      //   isTimeToMarkerGTENegativeSpeed,
      //   isTimeToMarkerLessThanMinimal,
      //   isTimeToMarkerBetweenNegativeSpeedAndMinimal,
      //   isMarkerFound,
      // })
      return isMarkerFound
    })
    // console.log(marker, 'MARKER')
    // console.log(currTime, 'CURR TIME')
    // currTime can be more or equal to marker time. To make time equal safely return to marker position
    if (marker && marker.slideEndTime < currTime) setTimeToPrevMark(true)
    if (marker && marker.slideEndTime > currTime) setTimeToNextMark(true)
    return marker
  }

  const getCurrentSlideIdFromTime = (time) => {
    if (!marksTime.length) return null
    return marksTime?.reduce((a, b) => (time > a.time && time < b.time && !a.chapter ? b : a))?.slideId
  }

  const showNavigationNotification = () => {
    // turned off
  }

  const recalculateCurrentTimePopup = (e) => {
    if (e.type === 'mouseleave') {
      setCurrentTimePopup(null)
      return null
    }
    if (!progressDimsRef.current || !marksTime.length) return null
    const { x, width } = progressDimsRef.current.getBoundingClientRect()
    // 10 is a sum of horizontal padding inside slider for rails
    const calcWidth = width - x
    const time = ((e.clientX - x - 10) * videoEl.duration) / calcWidth
    const chapter = marksTime.reduce((a, b) => (time > a.time && time < b.time && !a.chapter ? b : a))?.chapter
    let transform = { transform: `translateX(${e.clientX}px)` }
    // 200 is a maximum width of popup
    if (width - e.clientX < 200) transform = { transform: `translateX(-${width - e.clientX}px)`, right: 15 }
    const element = (
      <div className="current-time-popup" style={{ bottom: 70, zIndex: 6, ...transform }}>
        <span key="2">{chapter}</span>
        <span key="3">{secondsToHMS(time >= 0 ? time : 0)}</span>
      </div>
    )
    setCurrentTimePopup(element)
  }

  const handleOpenSubmenu = (key) => {
    setOpenSubmenu((prevState) => {
      if (key === prevState) return null
      else return key
    })
  }

  const onOpenChangeMenu = (isOpen) => {
    setIsMenuOpen(isOpen)
    if (!isOpen) setOpenSubmenu(null)
  }

  const menuItems = [
    video?.subtitlesEnabled
      ? {
          type: 'group',
          label: video?.url ? (
            <div
              className="item-group-submenu ant-dropdown-menu-item ant-dropdown-menu-title-content"
              onClick={() => handleOpenSubmenu(SUBMENU_KEY_DOWNLOAD)}
            >
              <Icon name="download" /> Download
              <Icon name="down_arrow" className="expand-icon" />
            </div>
          ) : null,
          key: [SUBMENU_KEY_DOWNLOAD, false],
          children:
            openSubmenu === SUBMENU_KEY_DOWNLOAD
              ? [
                  {
                    key: [SUBMENU_KEY_DOWNLOAD, 'url'],
                    label: <div className="interactive-player submenu">Video</div>,
                  },
                  {
                    key: [SUBMENU_KEY_DOWNLOAD, 'subtitlesUrl'],
                    label: <div className="interactive-player submenu">Subtitles</div>,
                  },
                  {
                    type: 'divider',
                  },
                ]
              : null,
        }
      : {
          label: video?.url ? (
            <>
              <Icon name="download" /> Download
            </>
          ) : null,
          key: [SUBMENU_KEY_DOWNLOAD, 'url'],
        },
    {
      label: (
        <div
          className="item-group-submenu ant-dropdown-menu-item ant-dropdown-menu-title-content"
          onClick={() => handleOpenSubmenu(SUBMENU_KEY_RATE)}
        >
          <Icon name="speed" />
          Playback speed
          <Icon name="down_arrow" className="expand-icon" />
        </div>
      ),
      type: 'group',
      key: SUBMENU_KEY_RATE,
      children:
        openSubmenu === SUBMENU_KEY_RATE
          ? PLAY_RATES.map((r) => ({
              key: [SUBMENU_KEY_RATE, r],
              label: (
                <div className="interactive-player submenu">
                  <Icon
                    name="check"
                    className={r === (playbackRate === 1 ? 'Normal' : playbackRate) ? 'selected' : 'normal'}
                  />
                  {r}
                </div>
              ),
            }))
          : null,
    },
  ]

  // avoid intermediate values error and ghost option in menu
  if (video?.subtitlesEnabled) {
    const subtitlesOption = {
      label: (
        <>
          <Icon name={isSubtitlesVisible ? 'subtitles' : 'subtitles_off'} /> Subtitles{' '}
          {isSubtitlesVisible ? 'off' : 'on'}
        </>
      ),
      key: ['subtitles'],
    }

    menuItems.splice(1, 0, subtitlesOption)
  }

  useEffect(() => {
    // load marks
    const obj = {}
    // NOTE: changed is it can lead to frustration with naming overlaps
    let accumTime = 0
    if (video.slides?.length && videoEl?.duration && !marksTime.length) {
      const markData = [{ time: 0, type: 'slide', slideEndTime: 0, nextSlideBeginTime: 0 }]
      for (let i = 0; i < video.slides.length; i++) {
        const slide = video.slides[i]
        const { nextSlideBeginTime, slideEndTime, correctedSlideDuration } = getSlideTimeShifts(
          slide,
          accumTime,
          i === video.slides.length - 1,
        )
        const isInteractive = isSlideInteractive(slide)
        slide.accumDuration = +accumTime.toFixed(3)
        slide.duration = correctedSlideDuration
        accumTime += correctedSlideDuration
        obj[(accumTime * 100) / videoEl.duration] = {
          time: +accumTime.toFixed(3),
          type: isInteractive ? 'interactive' : 'slide',
          key: slide.id,
          label: isInteractive ? (
            <div className="interactive-mark">
              <Icon name="bolt" />
            </div>
          ) : (
            <div className="mark-chapter"></div>
          ),
        }
        markData.push({
          time: +accumTime.toFixed(3),
          type: isInteractive ? 'interactive' : 'slide',
          slideId: slide.id,
          nextSlide: slide.nextSlide,
          chapter: slide.chapterTitle,
          slideEndTime,
          nextSlideBeginTime,
        })
      }
      // console.log(markData, 'MARKS TIME')
      setMarksTime(markData)
      setMarksRender(obj)
    }
    // TODO remove debug info
    if (videoEl?.duration) {
      console.log(+video.slides?.reduce((a, b) => a + b.duration, 0).toFixed(3), videoEl?.duration)
    }
  }, [video.slides?.length, videoEl?.duration])

  // check if it should stop video if there interactive elements
  useEffect(() => {
    // console.log(`FIRED at ${currTime}`)
    // pause video when interactive mark is achieved
    const marker = getInteractiveMarkerIfCloseToCurrTime()
    if (marker && marksTime.find((m) => m.slideEndTime === +currTime.toFixed(3))) {
      const slide = setSlideObjectsByMarker(marker)

      // do not allow to continue video if slide contain button or quiz
      const isHaveInteractiveObjects =
        slide?.answers?.length > 0 || slide.objects?.find((object) => object.action.type === 'slide') !== undefined

      if (isHaveInteractiveObjects && !allowNavigation) {
        setIsPlayDisabled(true)
      }
      if (isHaveInteractiveObjects) {
        setIsClickDisabled(true)
      }

      // if there no interactive elements on slide except link and it was clicked before do not stop on current slide
      if (!isHaveInteractiveObjects && slideClicked === slide.id) {
        setTimeToNextSlideBeginTime(marker)
        return
      } else {
        videoEl.pause()
        setPaused(true)
        setTimeout(() => {
          stopTimer(true)
        }, 500)
        // always hide controls when markers reached
        setControlsVisible('hidden')
        return
      }
    }

    // redirect to nextSlide, if present
    const simpleMarker = getSimpleMarkerIfCloseToCurrTime()
    if (simpleMarker && simpleMarker.nextSlide) {
      setNavigationSlideId(simpleMarker.nextSlide)
      setTimeout(() => handlePlayPause({}), 300)
    }

    // setting slideObjects by animation time
    const s =
      video?.slides?.find((s) => s.id === getCurrentSlideIdFromTime(currTime)) ||
      video?.slides?.[video?.slides?.length - 1]

    const fixRenderDelay = 0.25
    const currentActiveObjects = s?.objects?.filter(
      (o) =>
        currTime + fixRenderDelay >
          (o?.animation?.startTime ? s.accumDuration + o?.animation?.startTime : s.accumDuration) &&
        currTime + fixRenderDelay <=
          (o?.animation?.endTime > 0
            ? s.accumDuration + o.animation.endTime
            : Math.ceil(s.accumDuration + s.duration)) &&
        isNotQuestionSubObjectOnlyQuestions(o),
    )
    setCurrentSlide({ ...s, objects: currentActiveObjects })
    if (currTime >= videoEl?.duration.toFixed(3)) {
      setPaused(true)
      stopTimer(true)
      collectWhenFinished()
    }
  }, [currTime])

  useEffect(() => {
    if (videoRef.current) {
      setVideoEl(videoRef.current)
      videoRef.current.ontimeupdate = () => {
        setCurrTime(+videoRef.current.currentTime.toFixed(3), 'ontimeupdate event listener')
      }
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.volume = DEFAULT_VOLUME / 100
        setPlayerBoundingRect(videoRef.current.getBoundingClientRect())
        setShowLoader(false)
      }
    }
  }, [videoRef.current])

  useEffect(() => {
    // used for navigation from interactive element
    if (navigationSlideId) {
      let slideEndIndex = marksTime.findIndex((m) => m.slideId === navigationSlideId)
      // TODO slide was removed
      if (slideEndIndex === -1) return
      const t = marksTime[slideEndIndex - 1].nextSlideBeginTime
      videoEl.currentTime = t
      setNavigationSlideId(null)
      setTimeout(() => {
        // console.log(`TIMER SET CURRENT TIME TO ${t}`)
        setCurrTime(t, 'useEffect -> navigationSlideId -> setTimeout')
        handlePlayPause({ forcePlay: true })
      }, 500)
    }
  }, [navigationSlideId])

  useEffect(() => {
    // preload images, set slideId for objects with navigate to nextSlide, but nextSlide is empty
    let animations = ''
    if (video.slides) {
      // to set it only once per video
      // set it only once
      allowNavigation = !video?.locked

      for (let i = 0; i < video.slides.length; i++) {
        const slide = video.slides[i]
        // check for length as field 'objects' always exists
        if (!slide?.objects || slide?.objects?.length < 1) {
          continue
        }
        // if we ever reach this point then set flag to true
        // otherwise video has no interactive elements
        if (!isInteractive) {
          setIsInteractive(true)
        }
        for (const o of slide.objects) {
          animations += getAnimations({ obj: o })
          if (!o.src || !isPreloadImages) continue
          preloadMediaElement(o)
          // setting slide id if nextSlide is chosen during creation (empty or null)
          if (o?.action?.type === 'slide' && !o?.action?.slide) o.action.slide = video.slides[i + 1]?.id
          if (!o.animation) o.animation = { startTime: 0.5 }
        }
        for (const o of slide.defaultObjects) {
          preloadMediaElement(o)
        }

        if (slide?.answers?.length) {
          // setting slide id if nextSlide is chosen during creation (empty or null)
          slide.answers = slide.answers.map((a) => ({
            ...a,
            onWrong: a.onWrong?.slide ? a.onWrong : { ...a.onWrong, slide: video.slides[i + 1]?.id },
            onCorrect: a.onCorrect?.slide ? a.onCorrect : { ...a.onCorrect, slide: video.slides[i + 1]?.id },
          }))
        }
      }

      setCheckboxTemplate(video.slides.flatMap((slide) => slide.defaultObjects).filter(Boolean))
      setMainStyles(animations)
    }
    return () => setIsPreloadImages(false)
  }, [video])

  // avoid unnecessary initializations and memory leaks
  useEffect(() => {
    const handlePlayerResize = () => {
      // recalculate interactive object's dimensions if player resized
      if (videoEl) {
        setPlayerBoundingRect(videoEl.getBoundingClientRect())
      }
      if (renderElements.length) {
        setNeedRerenderAnswers(true)
      }
    }

    window.onresize = () => handlePlayerResize()

    return () => (window.onresize = null)
  }, [videoEl, renderElements])

  // bind play / pause on space click
  useEffect(() => {
    const handlePauseOnSpace = (event) => {
      if (event.code === 'Space' && !isClickDisabled) {
        handlePlayPause({})
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', handlePauseOnSpace)

    return () => {
      document.removeEventListener('keydown', handlePauseOnSpace)
    }
  }, [videoEl, isPlayDisabled, isClickDisabled])

  useEffect(() => {
    actualSlideId = currentSlide?.id
    setQuizSlides(currentSlide)
    if (!videoRef.current.paused) {
      startTimer(true)
    }
  }, [currentSlide?.id])

  return {
    paused,
    volume,
    muted,
    marksTime,
    marksRender,
    currTime,
    menuItems,
    isSubtitlesVisible,
    isMenuOpen,
    setIsMenuOpen,
    isChapterMenuOpen,
    setIsChapterMenuOpen,
    chapterMenuItems,
    renderElements,
    controlsVisible,
    mainStyles,
    allowNavigation,
    currentTimePopup,
    showLoader,
    messageContextHolder,
    isIOSFullScreen,
    menuItemsHandler,
    chapterMenuItemsHandler,
    setTimeToNextMark,
    setTimeToPrevMark,
    handleVolumeMute,
    handleVolumeChange,
    handlePlayPause,
    handleFullScreen,
    handleChangeTime,
    handleControlsVisibility,
    showNavigationNotification,
    recalculateCurrentTimePopup,
    setPaused,
    platform,
    onOpenChangeMenu,
    browser,
    isPlayDisabled,
    isClickDisabled,
    isVideoFinished,
  }
}
