import { Route, Routes } from 'react-router-dom'
import React, { Component, useState } from 'react'

import Player from './components/Player'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

const App = () => {
  const [video, setVideo] = useState({ data: {} })

  return (
    <ErrorBoundary
      fallback={
        <video width="100%" src={video.url} crossOrigin="anonymous" controls>
          {video?.subtitlesEnabled === 'vtt' && (
            <track
              srcLang={video.subtitlesLanguage}
              default={true}
              kind="subtitles"
              src={video.subtitlesUrl}
              label={video.subtitlesLanguage}
            />
          )}
          Your browser doesn't&apost support video player
        </video>
      }
    >
      <Routes>
        <Route path=":videoId" element={<Player video={video} setVideo={setVideo} />}></Route>
        <Route path="*" element={<Player video={video} setVideo={setVideo} />}></Route>
      </Routes>
    </ErrorBoundary>
  )
}

export default App
