import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4868ff',
          colorPrimaryActive: '#2d4ffc',
          colorPrimaryHover: 'rgba(72, 102, 255, 0.8)',
          colorLink: '#4868ff',
          colorLinkActive: '#2d4ffc',
          colorLinkHover: 'rgba(72, 102, 255, 0.8)',
          colorError: '#cf5d60',
          fontFamily: `'Basier Circle', Arial, sans-serif`,
        },
      }}
    >
      <App />
    </ConfigProvider>
  </BrowserRouter>,
)
