import React from 'react'
import { Spin } from 'antd'

import '../assets/styles/styles.less'

const Loader = () => {
  return (
    <div className="centered-loader">
      <Spin />
    </div>
  )
}

export default Loader
