import { useEffect } from 'react'
import { useScorm } from './useScorm'
import { useSearchParams } from 'react-router-dom'

// for interval
let watchSlideTimer = undefined
// if video was stopped, then keep time here
let durationWatched = 0

let saveInterval = undefined

let quizMetrics = { _id: undefined, slides: [], amountOfClicks: 0 }

let lastSlideId = undefined

export const useQuizMetrics = (props) => {
  const { currentSlide, paused, actualSlideId, video, videoId, isVideoFinished, setIsVideoFinished } = props

  const scorm = useScorm()
  const [search] = useSearchParams()

  const updateMetrics = async () => {
    const url = new URL(window.location !== window.parent.location ? document.referrer : document.location.href)
    // token presence indicates what request was made from /preview/ url in FE. We don't need to gather metrics from this endpoint and FE hosts
    if (search.has('token') && url?.hostname?.includes('elai.io')) return

    if (quizMetrics.slides?.length && video?.slides?.length) {
      scorm.completed = quizMetrics.isFinished ?? false
    }

    // axios throws unsafe header for keepalive
    const result = await fetch(`${process.env.REACT_APP_API_URL}/public/metrics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      keepalive: true,
      body: JSON.stringify(quizMetrics),
    }).then((res) => res.json())

    // if api created new session then we update stored data
    if (result.createNew === true) {
      quizMetrics._id = result.id
      localStorage.setItem('quizSessionId', result.id)
      localStorage.setItem('sessionVideoId', quizMetrics.videoId)
    }
    if (result.scorm) {
      scorm.score = result.scorm.score
      scorm.progress = result.scorm.progress
    }
  }

  // collect data when page loads
  const initializeMetrics = async (videoId) => {
    const url = window.location !== window.parent.location ? document.referrer : document.location.href
    quizMetrics.url = url
    if (scorm.enabled) {
      quizMetrics.studentName = scorm.studentName
      quizMetrics.studentEmail = scorm.studentEmail
    }
    // as some values may reset, recalculate anew
    quizMetrics.isFinished = false
    // if it's different video then the one user was watched then create new
    const prevSessionVideoId = localStorage.getItem('sessionVideoId')
    if (prevSessionVideoId !== videoId) {
      localStorage.clear()
      quizMetrics._id = undefined
      quizMetrics.videoId = videoId
      updateMetrics()
    } else {
      const prevSessionId = localStorage.getItem('quizSessionId')
      quizMetrics._id = prevSessionId
      quizMetrics.videoId = prevSessionVideoId
    }
  }

  const setQuizSlides = () => {
    if (currentSlide) {
      quizMetrics.isFinished = false
      const slideIndex = quizMetrics.slides.findIndex((slide) => slide.id === currentSlide.id)
      lastSlideId = currentSlide.id
      if (slideIndex === -1) {
        const questionObj = currentSlide.objects?.find((object) => object.question?.element === 'question')
        quizMetrics.slides.push({
          id: currentSlide.id,
          elementClicked: false,
          duration: currentSlide.duration,
          question: questionObj?.text,
        })
      }
    }
  }

  const setClick = () => {
    quizMetrics.slides.forEach((slide) => {
      if (currentSlide.id === slide.id) {
        slide.elementClicked = true
      }
    })
    quizMetrics.amountOfClicks++
  }

  const setAnswerClick = (userAnswers, result) => {
    const question = currentSlide.objects?.find((obj) => obj.question?.element === 'question')
    // select and narrow user answers to string values divided by coma
    const selectedAnswers = userAnswers
      .filter((answer) => answer.checked)
      .flatMap((answer) =>
        currentSlide.objects
          .filter((obj) => obj.id.includes(answer.answerId) && obj.question?.element === 'answer_text')
          .map((selected) => selected.text),
      )
      .join(',')

    quizMetrics.slides.forEach((slide) => {
      if (currentSlide.id === slide.id) {
        slide.answers = selectedAnswers
        slide.isAnswerCorrect = result
        if (question.text) {
          slide.question = question.text
        }
      }
    })

    scorm.interaction({
      id: currentSlide.id,
      description: question?.text,
      userResponse: userAnswers,
      result,
    })
    updateMetrics()
  }

  const setDurationWatched = (time) => {
    const index = quizMetrics.slides.findIndex((slide) => slide.id === actualSlideId)
    if (index === -1) {
      return
    }
    quizMetrics.slides[index] = {
      ...quizMetrics.slides[index],
      durationWatched: time / 10,
    }
  }

  const stopTimer = (shouldUpdate = false) => {
    clearInterval(watchSlideTimer)
    watchSlideTimer = undefined
    if (shouldUpdate) {
      setDurationWatched(durationWatched)
    }
  }

  // we should reset timer or keep time if user stopped video
  const startTimer = (reset = false) => {
    stopTimer(reset)
    if (reset) {
      durationWatched = 0
    }
    watchSlideTimer = setInterval(() => {
      durationWatched++
    }, 100)
  }

  const collectWhenFinished = () => {
    // mark that video is ended which means we need to calculate results
    quizMetrics.isFinished = true
    updateMetrics()
    setIsVideoFinished(true)
  }

  const setHasQuiz = () => {
    quizMetrics.hasQuiz = true
  }

  useEffect(() => {
    if (!paused) {
      saveInterval = setInterval(() => {
        updateMetrics()
      }, 20000)
    } else {
      clearInterval(saveInterval)
      saveInterval = undefined
    }
  }, [paused])

  const saveBeforeUnload = () => {
    // stop updating durationWatched when video is ended
    if (!isVideoFinished) {
      for (let index = 0; index < quizMetrics.slides.length; index++) {
        const slide = quizMetrics.slides[index]
        if (slide.id === lastSlideId) {
          slide.durationWatched = durationWatched / 10
        }
      }
    }
    updateMetrics()
  }

  useEffect(() => {
    // if it not null - save videId
    const prevSessionId = localStorage.getItem('quizSessionId')
    if (prevSessionId !== null) {
      quizMetrics._id = prevSessionId
    }
    const onVisibilityChange = () => {
      if (document.visibilityState === 'hidden') saveBeforeUnload()
    }

    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  const resetDurationWatched = () => {
    durationWatched = 0
  }

  useEffect(() => {
    if (video) {
      if (video?.slides) {
        initializeMetrics(videoId)
        video.slides.forEach((slide) => {
          if (Object.hasOwn(slide, 'answers')) {
            setHasQuiz()
          }
        })
      }
    }
  }, [video, videoId])

  return {
    setClick,
    stopTimer,
    setHasQuiz,
    startTimer,
    setQuizSlides,
    updateMetrics,
    setAnswerClick,
    initializeMetrics,
    collectWhenFinished,
    resetDurationWatched,
  }
}
