import React, { useEffect, useState } from 'react'

import Icon from '../components/Icon'

import {
  getAnswerGroupIdFromButtonId,
  getAnswerGroupIdFromObjectId,
  getAnswerIdFromObjectId,
  isCheckbox,
} from '../utils/helpers'

export const useElementsHandler = ({
  videoEl,
  setClick,
  setPaused,
  messageApi,
  currentSlide,
  setShowLoader,
  updateMetrics,
  renderElements,
  setAnswerClick,
  setSlideClicked,
  clickedElementId,
  submittedAnswers,
  setIsPlayDisabled,
  setRenderElements,
  setClickedElementId,
  setSubmittedAnswers,
  setNavigationSlideId,
  resetDurationWatched,
}) => {
  const [checkboxTemplate, setCheckboxTemplate] = useState([])
  const [needRerenderAnswers, setNeedRerenderAnswers] = useState(false)
  const [answerState, setAnswerState] = useState([])

  const modifyAnswerElements = (elements) => {
    if (!Array.isArray(elements)) return elements
    return elements.map((e) => {
      if (e.id.includes('button_send')) {
        if (submittedAnswers.some((a) => a.answerGroupId === getAnswerGroupIdFromButtonId(e.id))) {
          // change state of send button to disabled
          return { ...e, disabled: true }
        } else {
          return { ...e, disabled: false }
        }
      }
      if (!e.id.includes('answers_container')) return e
      const children = []
      for (const child of e.children) {
        const foundAnswer = answerState.find(
          (a) =>
            child.id.includes(`answer-${a.answerId}`) &&
            child.id.includes(a.answerGroupId) &&
            child.id.includes('answer_checkbox'),
        )
        if (foundAnswer) {
          const { src } = checkboxTemplate.find(
            (c) => c.answerGroupId === foundAnswer.answerGroupId && c.checked === foundAnswer.checked,
          )
          const obj = {
            ...child,
            style: {
              ...child.style,
              background: `no-repeat url("${src}") 0% 0% / ${child.style.width}px ${child.style.height}px`,
            },
          }
          children.push(obj)
          continue
        }
        children.push(child)
      }
      return { ...e, children }
    })
  }

  const initAnswerState = ({ objects }) => {
    const state = [...answerState]
    for (const obj of objects) {
      if (!isCheckbox(obj)) continue
      const answerId = getAnswerIdFromObjectId(obj.id)
      const answerGroupId = getAnswerGroupIdFromObjectId(obj.id)
      if (!state.find((s) => s.answerId === answerId && s.answerGroupId === answerGroupId))
        state.push({ answerId, answerGroupId, checked: false, type: obj.question.type })
    }
    setAnswerState(state)
  }

  const setSlideIdWithTimeout = (slideId) => {
    setShowLoader(true)
    setTimeout(() => {
      setNavigationSlideId(slideId)
      setShowLoader(false)
    }, 2000)
  }

  // quiz button click
  const handleSendAnswer = (elementId) => {
    const answerGroupId = getAnswerGroupIdFromButtonId(elementId)
    const { answers, feedback, onCorrect, onWrong, answersInteractions } = currentSlide.answers.find(
      (a) => a.id.toString() === answerGroupId,
    )
    // keep only answered questions
    const userAnswers = answerState.filter((a) => a.answerGroupId === answerGroupId).filter((item) => item.checked)
    // convert right answers to string
    const stringifiedAnswers = answers.join(',').split(',')
    // store all results
    const success = userAnswers.map((item) => stringifiedAnswers.includes(item.answerId))

    if (success.length === 0) {
      messageApi.error({ content: 'Please select an answer', icon: <Icon name="error" />, duration: 3.5 })
      return
    }

    // filter selected answers
    const nextSlide =
      answersInteractions?.length > 0
        ? userAnswers.length > 1
          ? answersInteractions.filter((answer) => userAnswers.some((userA) => answer.id.toString() === userA.answerId))
          : answersInteractions.filter((answer) => answer.id.toString() === userAnswers[0].answerId)
        : []

    // check for existing action for answer
    const result =
      nextSlide && nextSlide[0]?.interactivity?.slide !== null ? nextSlide[0]?.interactivity?.slide : undefined

    // check if there any wrong answers
    if (success.length === answers.length && !success.includes(false)) {
      setAnswerClick(userAnswers, true)

      if (feedback && onCorrect?.feedbackMessage) {
        messageApi.success({ content: onCorrect.feedbackMessage, icon: <Icon name="success" />, duration: 3.5 })
      }
      if (onCorrect?.slide) {
        setSlideIdWithTimeout(result ? result : onCorrect.slide)
      }
    } else {
      setAnswerClick(userAnswers, false)
      if (feedback && onWrong?.feedbackMessage) {
        messageApi.error({ content: onWrong.feedbackMessage, icon: <Icon name="error" />, duration: 3.5 })
      }
      if (onWrong?.slide) {
        setSlideIdWithTimeout(result ? result : onWrong.slide)
      }
    }
    resetDurationWatched()
    setSubmittedAnswers((prev) => [...prev.filter((p) => p.answerGroupId !== answerGroupId), ...userAnswers])

    setNeedRerenderAnswers(true)
  }

  const saveSelectedAnswers = (prevAnswers, answerId, answerGroupId) => {
    const selectedAnswerIndex = prevAnswers.findIndex(
      (p) => p.answerId === answerId && p.answerGroupId === answerGroupId,
    )
    const selectedAnswer = prevAnswers[selectedAnswerIndex]
    if (selectedAnswer.type === 'radio') {
      if (!selectedAnswer.checked) {
        const markedAnswer = prevAnswers.find(
          (answer) => answer.checked && answer.answerGroupId === selectedAnswer.answerGroupId,
        )
        if (markedAnswer) markedAnswer.checked = false
        selectedAnswer.checked = true
      }
    } else {
      selectedAnswer.checked = !selectedAnswer.checked
    }
    return [...prevAnswers]
  }

  const handleElementClick = (elementId) => {
    const obj = currentSlide?.objects?.find((o) => elementId === o.id)
    setClick()
    if (obj?.action?.type) {
      const { type, link, slide } = obj.action
      if (type === 'link') {
        if (link) {
          // pause video when user redirected
          setPaused(true)
          setSlideClicked(currentSlide.id)
          videoEl.pause()
          const a = document.createElement('a')
          a.href = link
          a.target = '_blank'
          a.click()
          setIsPlayDisabled(false)
        }
      }
      if (type === 'slide') {
        setSlideClicked('')
        setNavigationSlideId(slide)
        setIsPlayDisabled(false)
        resetDurationWatched()
        updateMetrics()
      }
    }
    if (elementId.includes('answers_group-answer')) {
      const answerId = getAnswerIdFromObjectId(elementId)
      const answerGroupId = getAnswerGroupIdFromObjectId(elementId)
      setAnswerState((prevAnswers) => saveSelectedAnswers(prevAnswers, answerId, answerGroupId))
      setSubmittedAnswers([])
      setNeedRerenderAnswers(true)
    }
    if (elementId.includes('button_send')) {
      setSlideClicked('')
      handleSendAnswer(elementId)
      setIsPlayDisabled(false)
    }
    setClickedElementId(null)
  }

  useEffect(() => {
    if (answerState.length) {
      setRenderElements(modifyAnswerElements(renderElements))
    }
  }, [answerState])

  useEffect(() => {
    if (currentSlide?.objects?.length) {
      initAnswerState(currentSlide)
      setNeedRerenderAnswers(true)
    }
  }, [currentSlide?.objects])

  useEffect(() => {
    if (clickedElementId) handleElementClick(clickedElementId)
  }, [clickedElementId])

  useEffect(() => {
    if (renderElements.length && needRerenderAnswers) {
      setRenderElements(modifyAnswerElements(renderElements))
      setNeedRerenderAnswers(false)
    }
  }, [renderElements, needRerenderAnswers])

  return { setNeedRerenderAnswers, setCheckboxTemplate }
}
