import { createRef, useEffect } from 'react'

import { getAnswerGroupIdFromObjectId, isCheckbox, isClickable } from '../utils/helpers'

const CANVAS_HEIGHT = 360
const CANVAS_WIDTH = 640

export const useRender = ({
  playerBoundingRect,
  currentSlide,
  setRenderElements,
  setClickedElementId,
  setNeedRerenderAnswers,
}) => {
  if (playerBoundingRect?.width / playerBoundingRect?.height > 1.77777) {
    const newWidth = playerBoundingRect.height * 1.77777
    playerBoundingRect.x = (playerBoundingRect.width - newWidth) / 2
    playerBoundingRect.width = newWidth
  }
  const ratioX = playerBoundingRect?.width / CANVAS_WIDTH
  const ratioY = playerBoundingRect?.height / CANVAS_HEIGHT

  const getAnimations = ({ obj }) => {
    let style = isCheckbox(obj) ? '-webkit-transition: 0.3s ease-in-out 0.1s;\ntransition: 0.3s ease-in-out 0.1s;' : ''
    let transform = ''
    transform += obj.scaleX ? `scaleX(${obj.scaleX}) ` : ''
    transform += obj.scaleY ? `scaleY(${obj.scaleY}) ` : ''
    transform += obj.rotate ? `rotate(${obj.rotate}deg) ` : ''
    style += transform.length ? `transform: ${transform.trim()}; transform-origin: top left;` : ''
    const trTime = 300
    const trDelay = 300
    const transitions = `.${obj.id}-enter {opacity: 0;} .${obj.id}-enter-active {opacity: 1; -webkit-transition: opacity ${trTime}ms ${trDelay}ms; transition: opacity ${trTime}ms ${trDelay}ms;} .${obj.id}-exit {opacity: 1;} .${obj.id}-exit-active {opacity: 0; -webkit-transition: opacity ${trTime}ms; transition: opacity ${trTime}ms;}`
    if (style.length) style = `\n#${obj.id} {${style}}`
    return `${style}${transitions}\n`
  }

  const getElementProps = ({ obj, parent }) => {
    let onClick = undefined
    const style = {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      maxHeight: 'unset',
    }

    style.width = obj.width * ratioX
    style.height = obj.height * ratioY
    style.zIndex = 3
    style.borderRadius = obj.borderRadius ? obj.borderRadius * ratioX : undefined

    if (parent) {
      style.top = (parent.top + obj.top) * ratioY
      style.left = (parent.left + obj.left) * ratioX
    } else {
      style.top = obj.top * ratioY + (document.fullscreenElement ? playerBoundingRect.top : 0)
      style.left = playerBoundingRect.x + obj.left * ratioX + (document.fullscreenElement ? playerBoundingRect.left : 0)
    }
    if (obj.opacity && obj.opacity !== 1) style.opacity = obj.opacity

    if (isClickable(obj)) {
      style.cursor = 'pointer'
      onClick = () => setClickedElementId(obj.id)
    }

    return { style, onClick }
  }

  const generateElement = ({ obj, parent }) => {
    if (!obj) return

    const { style, onClick } = getElementProps({ obj, parent })

    style.nodeRef = createRef()
    if (obj.type === 'video') {
      return {
        id: obj.id,
        style: { ...style, type: 'video' },
        onClick,
        src: obj.src,
        autoPlay: true,
        loop: true,
        controls: false,
      }
    }

    // hack to create needed element type
    style.type = 'div'
    style.background = `no-repeat url("${!isCheckbox(obj) ? obj.src : ''}") 0% 0% / ${style.width}px ${style.height}px`
    return { style, id: obj.id, onClick }
  }

  const generateSubmitButton = (obj) => {
    const { style } = getElementProps({ obj })
    const { top, left, width, height } = style

    const scaleX = (width / obj.width) * obj.scaleX
    const scaleY = (height / obj.height) * obj.scaleY
    return {
      type: 'primary',
      htmlType: 'submit',
      className: 'answer-container-button_send',
      id: obj.id,
      onClick: () => setClickedElementId(obj.id),
      style: {
        type: 'button',
        transform: `scale(${scaleX}, ${scaleY})`,
        transformOrigin: 'top left',
        position: 'absolute',
        zIndex: 3,
        top,
        left,
        nodeRef: createRef(),
      },
      title: 'Submit',
    }
  }

  const generateAnswerGroup = (objects) => {
    const children = []
    const answerGroupObjects = objects.filter((o) => o.id.includes('-answers_group-answer'))
    const topmost = answerGroupObjects.reduce((a, c) => (a.top < c.top ? a : c))

    const parent = objects.find((o) => o.id.includes('answers_container'))
    for (const obj of objects) {
      if (!obj.id.includes('answers_group')) continue
      children.push(generateElement({ obj, parent: { top: -topmost.top, left: -parent.left } }))
    }

    const { style } = getElementProps({ obj: parent })
    style.flexDirection = 'column'
    style.overflowY = 'auto'
    style.overflowX = 'hidden'
    style.type = 'div'
    style.nodeRef = createRef()
    // for scroll
    style.width += 20
    style.height += 3

    return { style, key: parent.id, id: parent.id, children }
  }

  const generateRenderElements = (objects) => {
    const result = []

    if (objects) {
      const renderedAnswers = []

      for (const obj of objects) {
        const answerGroup = getAnswerGroupIdFromObjectId(obj.id)
        if (answerGroup) {
          if (renderedAnswers.includes(answerGroup)) continue
          renderedAnswers.push(answerGroup)
          result.push(generateAnswerGroup(objects.filter((o) => o.id.includes(answerGroup))))
          continue
        }
        if (obj.id.includes('answers_container')) continue

        if (obj.id.includes('button_send')) {
          result.push(generateSubmitButton(obj))
          continue
        }

        result.push(generateElement({ obj }))
      }
    }
    setRenderElements(result)
  }

  useEffect(() => {
    if (!currentSlide) return
    generateRenderElements(currentSlide.objects)
    if (currentSlide.objects) setNeedRerenderAnswers(true)
  }, [currentSlide?.objects, playerBoundingRect])

  return { getAnimations }
}
