const secondsToHMS = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600)
  hours = hours === 0 ? '' : hours + ':'
  totalSeconds %= 3600
  let minutes = Math.floor(totalSeconds / 60)
  minutes = minutes < 10 && hours.length ? '0' + minutes + ':' : minutes + ':'
  let seconds = Math.ceil(totalSeconds % 60)
  seconds = seconds < 10 ? '0' + seconds : seconds
  return totalSeconds ? `${hours}${minutes}${seconds}` : '0:00'
}

const getAnswerIdFromObjectId = (objectId) => {
  // let answerId = objectId.match(/(?<=answers_group-answer-)([0-9]*)/g)
  const match = objectId.match(/answers_group-answer-([0-9]*)/g)
  const answerId = match ? match.map((m) => m.replace('answers_group-answer-', '')) : null
  return answerId ? answerId[0] : undefined
}

const getAnswerGroupIdFromObjectId = (objectId) => {
  // let answerId = objectId.match(/([0-9-]*)(?=-answers_group)/g)
  const match = /([0-9-]*)(?=-answers_group)/g.exec(objectId)
  return match ? match[1] : undefined
}

const getAnswerGroupIdFromButtonId = (objectId) => {
  // let answerId = objectId.match(/([0-9-]*)(?=-button_send)/g)
  const match = /([0-9-]*)-button_send/g.exec(objectId)
  return match ? match[1] : undefined
}

const isCheckbox = (obj) => obj.id.includes('answer_checkbox')

const isClickable = (obj) => obj.id.includes('answers_group-answer') || obj?.action

const isNotQuestionSubObject = (obj) =>
  !obj.id.includes('answer') &&
  !obj.id.includes('background') &&
  !obj.id.includes('question') &&
  !obj.id.includes('button_send') &&
  !obj?.action

const isNotQuestionSubObjectOnlyQuestions = (obj) =>
  !obj.id.includes('answer') &&
  !obj.id.includes('background') &&
  !obj.id.includes('question') &&
  !obj.id.includes('button_send')

const preloadMediaElement = (object) => {
  if (!object.src) return
  const element = object.type === 'video' ? document.createElement('video') : new Image()
  element.crossOrigin = 'anonymous'
  element.src = object.src
}

const isSlideInteractive = (slide) => slide.objects?.some((o) => !isNotQuestionSubObject(o))

/** Example of video duration of 4 same slides with different transitions
 * duration=22.743991 - fade
 * duration=24.063991 - fade white
 * duration=24.063991 - fade black
 * duration=24.063991 - no transition
 * duration=22.743991 - slide up
 * duration=22.743991 - wipe
 * duration=22.743991 - smooth
 */

/**
 * Calculates shifted time depending on slide transition and availability of quiz elements on it. Also corrects slide duration to correctly set marks on progress bar
 * @param {Object} slide { duration, objects, transition }
 * @param accumTime
 * @param isLastSlide for not to apply any correction for last slide duration
 * @returns {{slideEndTime: number, correctedSlideDuration: number, nextSlideBeginTime: number}}
 * slideEndTime - used to set time in the end of the slide. For quiz objects
 * nextSlideBeginTime - used to set to begging of the next slide
 */
const getSlideTimeShifts = (slide, accumTime, isLastSlide) => {
  const isColorOrNoTransition = ['fadewhite', 'fadeblack', null, undefined].includes(slide.transition)
  const isInteractive = isSlideInteractive(slide)
  let transitionShift = isColorOrNoTransition ? -0.02 : 0.4
  transitionShift = isLastSlide ? -0.02 : transitionShift
  let correctedSlideDuration = slide.duration - transitionShift

  let slideEndTime = isInteractive ? -0.2 : 0
  let nextSlideBeginTime = 0.3

  slideEndTime += accumTime + correctedSlideDuration
  nextSlideBeginTime += accumTime + correctedSlideDuration

  slideEndTime = +slideEndTime.toFixed(3)
  nextSlideBeginTime = +nextSlideBeginTime.toFixed(3)
  correctedSlideDuration = +correctedSlideDuration.toFixed(3)

  return { nextSlideBeginTime, slideEndTime, correctedSlideDuration }
}

export {
  secondsToHMS,
  getAnswerGroupIdFromObjectId,
  getAnswerIdFromObjectId,
  getAnswerGroupIdFromButtonId,
  isCheckbox,
  isClickable,
  isNotQuestionSubObject,
  isNotQuestionSubObjectOnlyQuestions,
  preloadMediaElement,
  getSlideTimeShifts,
  isSlideInteractive,
}
