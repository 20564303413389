import { useEffect } from 'react'
import Scorm from '../utils/scorm'

const scorm = new Scorm()

export const useScorm = () => {
  useEffect(() => {
    if (!window.videoData) return

    scorm.init()

    return () => {
      scorm.close()
    }
  }, [])

  return scorm
}
