const COMPLETION_STATUS = {
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
}

const SUCCESS_STATUS = {
  PASSED: 'passed',
  FAILED: 'failed',
}

const RESULT = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
}

const EXIT_MODE = {
  SUSPEND: 'suspend',
  LOGOUT: 'logout',
  NORMAL: 'normal',
  DEFAULT: '',
}

const fieldMapping = {
  studentId: {
    1.2: 'cmi.core.student_id',
    2004: 'cmi.learner_id',
  },
  studentEmail: {
    1.2: 'cmi.core.student_id',
    2004: 'cmi.learner_id',
  },
  studentName: {
    1.2: 'cmi.core.student_name',
    2004: 'cmi.learner_name',
  },
  score: {
    1.2: 'cmi.core.score.raw',
    2004: 'cmi.score.scaled',
  },
  exit: {
    1.2: 'cmi.core.exit',
    2004: 'cmi.exit',
  },
  timeSpent: {
    1.2: 'cmi.core.session_time',
    2004: 'cmi.session_time',
  },
  completionStatus: {
    1.2: 'cmi.core.lesson_status',
    2004: 'cmi.completion_status',
  },
  successStatus: {
    1.2: 'cmi.core.lesson_status',
    2004: 'cmi.success_status',
  },
}

const mapField = (field, version) => {
  if (!fieldMapping[field]) return field

  if (typeof fieldMapping[field] === 'object') {
    return fieldMapping[field][version]
  }
  return fieldMapping[field]
}

/**
 * @property {string} studentId
 * @property {string} studentEmail
 * @property {string} studentName
 * @property {string} score - 0..1
 * @property {string} exit - suspend|logout|normal
 * @property {number} timeSpent
 * @property {string} completionStatus - completed|incomplete
 * @property {string} successStatus - passed|failed
 */
class Scorm {
  #enabled = false
  #completed = false
  #version = null

  interactionSequenceId = 0

  constructor() {
    this.scormApi = window.pipwerks.SCORM
    if (!this.scormApi) return

    const ctx = this
    Object.keys(fieldMapping).forEach((key) => {
      Object.defineProperty(ctx, key, {
        get: () => ctx.get(key),
        set: (value) => {
          ctx.set(key, value)
        },
        enumerable: true,
        configurable: false,
      })
    })
  }

  init() {
    if (!this.scormApi || this.#enabled) return
    this.#enabled = this.scormApi.init()
    this.#version = this.scormApi.version
  }

  close() {
    if (!this.#enabled) return
    this.set('exit', EXIT_MODE.DEFAULT)
    this.scormApi.quit()
  }

  save() {
    if (!this.#enabled) return
    this.scormApi.save()
  }

  get enabled() {
    return this.#enabled
  }

  get version() {
    return this.#version
  }

  set progress(value) {
    if (!this.#enabled || this.#version !== '2004') return
    this.scormApi.set('cmi.progress_measure', value)
  }

  set completed(value) {
    const status = value ? COMPLETION_STATUS.COMPLETED : COMPLETION_STATUS.INCOMPLETE
    this.set('completionStatus', status)
    if (value) {
      this.success = true
    } else {
      this.save()
    }
  }

  get completed() {
    return this.#completed
  }

  set success(value) {
    const status = value ? SUCCESS_STATUS.PASSED : SUCCESS_STATUS.FAILED
    this.set('successStatus', status)
  }

  interaction({ id, type = 'choise', description, correctResponse, userResponse, result, weighting = 1 }) {
    if (!this.#enabled) return

    const sequenseId = this.interactionSequenceId++
    const prefix = `cmi.interactions.${sequenseId}`

    // TODO: remove after debugging on real LMS
    console.log(`${prefix}.result`, {
      id,
      type,
      description,
      correctResponse,
      userResponse,
      result,
      weighting,
    })

    // Map interaction data to cmi.interaction keys
    this.scormApi.set(`${prefix}.id`, `interaction-${id}`)
    this.scormApi.set(`${prefix}.type`, type)
    this.scormApi.set(`${prefix}.description`, description)
    this.scormApi.set(`${prefix}.student_response`, userResponse)
    this.scormApi.set(`${prefix}.learner_response`, userResponse)
    this.scormApi.set(`${prefix}.result`, result ? RESULT.CORRECT : RESULT.INCORRECT)
    this.scormApi.set(`${prefix}.weighting`, weighting)
    if (correctResponse) {
      this.scormApi.set(`${prefix}.correct_responses.0.pattern`, correctResponse)
    }
  }

  get(key) {
    if (!this.#enabled) return null
    return this.scormApi.get(mapField(key, this.#version))
  }

  set(key, value) {
    if (!this.#enabled) return false
    return this.scormApi.set(mapField(key, this.#version), value)
  }
}

export default Scorm
