import axios from 'axios'
import { notification } from 'antd'

const getToken = () => localStorage.getItem('token')

const request = async ({
  method,
  url,
  data,
  headers,
  params,
  cancelToken,
  allowedStatus,
  errorToDescriptionConverter,
  responseType,
  onUploadProgress,
}) => {
  const client = axios.create({ baseURL: process.env.REACT_APP_API_URL })
  headers = {
    ...headers,
    Authorization: `Bearer ${getToken()}`,
  }
  const subAccountId = localStorage.getItem('subAccountId')
  if (subAccountId) headers['sub-account-id'] = subAccountId

  let response
  try {
    response = await client({ method, url, data, params, headers, responseType, onUploadProgress, cancelToken })
    return response.data
  } catch (error) {
    if (allowedStatus) {
      if (error.response && allowedStatus === error.response.status) return false
      else if (allowedStatus === 'network-error' && !error.response) return false
    }

    let description = ''
    let message = error.toString()
    if (error.response) {
      if (error.response?.data?.isCustomNotification) {
        message = error.response.data.message
        description = errorToDescriptionConverter ? (
          errorToDescriptionConverter(error.response.data.context)
        ) : (
          <pre>{JSON.stringify({ ...error.response.data.context }, null, 2)}</pre>
        )
      } else {
        switch (error.response.status) {
          case 400:
            message = 'Invalid data'
            break
          case 404:
            message = 'Video not found'
            break
          case 429:
            message = 'Too many requests'
            break
          case 500:
            message = 'Error occurred'
            break
          default:
            message = `Server error ${error.response.status}`
            break
        }
        if (error.response.data.message) {
          description += error.response.data.message
        }
        if (error.response.data.length && error.response.data[0].message) {
          description += error.response.data[0].message
        }
      }
    }

    notification.destroy('api-error')
    setTimeout(() => {
      // it should be called on next event-loop iteration, because antd&react don't update in time corresponding internal state
      notification.error({ message, description, duration: null, key: 'api-error' })
    }, 100)
    return false
  }
}

export { request }
