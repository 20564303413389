import { useEffect, useState } from 'react'
import { IOS_PLAYER_HEIGHT } from '../constants/constants'

/**
 * use it for store initial sizes of wrapper and player
 */
const playerSizes = { playerX: '', playerY: '', wrapperX: '', wrapperY: '' }

/**
 * Get platform and browser which user uses and
 * update player with hes functionality according to that
 *
 * @param {HTMLVideoElement} videoRef reference to video tag
 * @param {HTMLDivElement} wrapperRef reference to div tag
 *
 * @return {{platform: string, browser: string}} return platform and browser of device
 */
export const usePlatformSpecific = ({ videoRef, wrapperRef, isIOSFullScreen }) => {
  const [platform, setPlatform] = useState('')
  const [browser, setBrowser] = useState('')

  /**
   * as there no need to re-use keep it inside here
   * otherwise wrap in callback and add as dependencies for this useEffect
   */
  useEffect(() => {
    // set on what user access player
    const checkPlatform = () => {
      if (typeof window === `undefined` || typeof navigator === `undefined`) {
        setBrowser(undefined)
        setPlatform(undefined)
      }

      if (window.navigator.userAgent.includes('Windows')) {
        setPlatform('windows')
        if (window.navigator.userAgent.includes('Edg')) {
          setBrowser('edge')
        } else if (window.navigator.userAgent.includes('Firefox')) {
          setBrowser('Firefox')
        } else if (window.navigator.userAgent.includes('Chrome')) {
          setBrowser('chrome')
        }
      } else if (window.navigator.userAgent.includes('iPhone')) {
        setPlatform('ios')
        if (window.navigator.userAgent.includes('CriOS')) {
          setBrowser('chrome')
        } else {
          setBrowser('safari')
        }
      } else {
        /**
         * TODO: There is no checks for Macs and other apple devices, same with android
         * so for now they are undefined as have no way to check it
         */
        setBrowser(undefined)
        setPlatform(undefined)
      }
    }

    checkPlatform()
  }, [])

  /**
   * TODO: if needed split logic into different hooks to track orientation change if necessary
   * move some logic to player if needed when doing refactor or some kinda of improvement
   */
  useEffect(() => {
    // fit for available page

    // this not resolve problem on safari only when loading
    const handleOrientationChange = () => {
      if (platform === 'ios' && browser === 'safari') {
        // is landscape
        if (window.screen.orientation.type !== 'portrait-primary') {
          // keep initial sizes
          playerSizes.playerX = videoRef.current.style.width
          playerSizes.playerY = videoRef.current.style.height
          playerSizes.wrapperX = wrapperRef.current.style.width
          playerSizes.wrapperY = wrapperRef.current.style.height
          videoRef.current.style.height = '100%'
          videoRef.current.style.width = '100%'
          if (isIOSFullScreen) wrapperRef.current.style.height = IOS_PLAYER_HEIGHT
          // if portrait mode
        } else {
          // reset set values to initial
          wrapperRef.current.style.height = playerSizes.wrapperY
          wrapperRef.current.style.width = playerSizes.wrapperX
          videoRef.current.style.height = playerSizes.playerY
          videoRef.current.style.width = playerSizes.playerX
        }
      }
    }

    if (platform === 'ios') {
      handleOrientationChange()
    }

    // for some reason on chrome it lead to always 'moving' elements
    if (platform === 'ios' && browser === 'safari') {
      //update sizes on orientation change
      window.addEventListener('orientationchange', handleOrientationChange)
      return () => {
        window.removeEventListener('orientationchange', handleOrientationChange)
      }
    }
  }, [platform, wrapperRef, videoRef])

  return {
    platform,
    browser,
  }
}

/**
 * NOTE: navigator.vendor is deprecated and exists sole for compatibility and later can be dropped
 */
