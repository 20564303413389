import { Button, Dropdown, Slider } from 'antd'
import React, { useEffect, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Icon from './Icon'
import Loader from './Loader'

import { request } from '../utils/api'
import { secondsToHMS } from '../utils/helpers'
import { usePlayerState } from '../hooks/usePlayerState'
import { IOS_PLAYER_HEIGHT } from '../constants/constants'

import '../assets/styles/styles.less'

const Player = (props) => {
  const { video, setVideo } = props
  // refs
  const videoRef = useRef(null)
  const wrapperRef = useRef(null)
  const progressDimsRef = useRef(null)
  // params
  const params = useParams()
  const [search] = useSearchParams()

  const {
    paused,
    volume,
    muted,
    marksTime,
    marksRender,
    currTime,
    menuItems,
    isSubtitlesVisible,
    isMenuOpen,
    setIsMenuOpen,
    isChapterMenuOpen,
    setIsChapterMenuOpen,
    chapterMenuItems,
    renderElements,
    controlsVisible,
    mainStyles,
    allowNavigation,
    currentTimePopup,
    showLoader,
    messageContextHolder,
    isIOSFullScreen,
    menuItemsHandler,
    chapterMenuItemsHandler,
    handleVolumeMute,
    handleVolumeChange,
    handlePlayPause,
    handleFullScreen,
    handleChangeTime,
    setTimeToPrevMark,
    setTimeToNextMark,
    handleControlsVisibility,
    showNavigationNotification,
    recalculateCurrentTimePopup,
    platform,
    onOpenChangeMenu,
    isPlayDisabled,
    isClickDisabled,
    isVideoFinished,
  } = usePlayerState({
    video,
    search,
    videoRef,
    wrapperRef,
    progressDimsRef,
    videoId: window.videoData?.id ?? params.videoId,
  })

  const handleMenuClose = () => {
    setTimeout(() => {
      setIsMenuOpen(false)
      setIsChapterMenuOpen(false)
    }, 1000)
  }

  const fetchVideo = async () => {
    let { videoId } = params
    if (window.videoData) videoId = window.videoData.id + '?s=' + window.videoData.signature

    let url
    if (localStorage.getItem('token')) {
      url = `videos/player/${videoId}`
    } else {
      url = `public/player/${videoId}`
    }
    const response = await request({ url })
    // TODO: maybe add some handling if fetch is failed
    if (response) {
      setVideo(response)
    }
  }

  useEffect(() => {
    if (search.get('token')) {
      localStorage.setItem('token', search.get('token'))
    } else {
      localStorage.removeItem('token')
    }
    if (search.get('subAccountId')) {
      localStorage.setItem('subAccountId', search.get('subAccountId'))
    } else {
      localStorage.removeItem('subAccountId')
    }
    if (params.videoId ?? window.videoData) {
      fetchVideo()
    }
  }, [params?.videoId])

  const playButtonIcon = paused ? (isVideoFinished ? 'repeat' : 'play_rounded') : 'pause_rounded'

  return (
    <div
      style={{
        height: isIOSFullScreen && platform === 'ios' ? IOS_PLAYER_HEIGHT : '100vh',
        width: '100hv',
        display: platform === 'ios' ? 'flex' : 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="interactive-player" ref={wrapperRef} onMouseLeave={handleMenuClose} id="interactive-player">
        {messageContextHolder}
        <style>{mainStyles}</style>

        <TransitionGroup className="render-elements">
          {renderElements.map((e) => (
            <CSSTransition key={e.id} timeout={700} nodeRef={e.style.nodeRef} classNames={e.id}>
              <>
                {e.style.type === 'button' && (
                  <Button {...e} ref={e.style.nodeRef}>
                    {e.title}
                  </Button>
                )}
                {e.style.type === 'video' && <video {...e} ref={e.style.nodeRef} crossOrigin="anonymous"></video>}
                {e.style.type === 'div' && (
                  <div {...e} ref={e.style.nodeRef}>
                    {e.children && e.children.map((c) => <div {...c} key={c.id} />)}
                  </div>
                )}
              </>
            </CSSTransition>
          ))}
        </TransitionGroup>
        <div id="menu-container"></div>
        {showLoader && <Loader />}
        <video
          ref={videoRef}
          width="100%"
          id="video"
          style={{ cursor: isClickDisabled ? 'inherit' : 'pointer' }}
          onClick={isClickDisabled ? null : handlePlayPause}
          src={video.url}
          crossOrigin="anonymous"
          preload="metadata"
          playsInline={platform === 'ios' ? true : undefined}
        >
          {video?.subtitlesEnabled === 'vtt' && isSubtitlesVisible && (
            <track
              srcLang={video.subtitlesLanguage}
              default={true}
              kind="subtitles"
              // if variable is false then src will have false as a value instead of a string
              src={video.subtitlesUrl || ''}
              label={video.subtitlesLanguage}
            />
          )}
          Your browser doesn't&apost support video player
        </video>
        {currentTimePopup}
        <div className="controls-wrapper">
          <div className="controls" id="controls" data-state={controlsVisible}>
            <div className="progress-wrapper">
              <div
                ref={progressDimsRef}
                onMouseMove={recalculateCurrentTimePopup}
                onMouseLeave={recalculateCurrentTimePopup}
              >
                <Slider
                  defaultValue={0}
                  showInfo={false}
                  marks={marksRender}
                  onChange={handleChangeTime}
                  value={(currTime * 100) / videoRef?.current?.duration}
                  tooltip={{ open: false }}
                />
              </div>
            </div>
            <div className="buttons-wrapper">
              <div className="left">
                <Icon
                  name="left_arrow_long"
                  onClick={allowNavigation ? () => setTimeToPrevMark(false) : showNavigationNotification}
                  className={`button ${marksTime[0]?.time >= currTime || !allowNavigation ? 'disabled' : ''}`}
                />
                <Icon
                  name={playButtonIcon}
                  className={`play ${isPlayDisabled ? 'disabled-icon' : ''}`}
                  onClick={handlePlayPause}
                />
                <Icon
                  name="right_arrow_long"
                  onClick={allowNavigation ? () => setTimeToNextMark(false) : showNavigationNotification}
                  className={`button ${videoRef?.current?.duration === currTime || !allowNavigation ? 'disabled' : ''}`}
                />
              </div>
              <div className="right">
                <div className="volume-wrapper">
                  <Button type="text">
                    <Slider
                      value={muted ? 0 : volume}
                      onChange={handleVolumeChange}
                      defaultValue={volume}
                      tooltip={{ open: false }}
                    />
                    <Icon name={muted ? 'muted' : 'volume'} onClick={handleVolumeMute} />
                  </Button>
                </div>
                <span className="time-wrapper">
                  {currTime ? secondsToHMS(Math.ceil(currTime)) : '0:00'} / {secondsToHMS(videoRef?.current?.duration)}
                </span>
                <Dropdown
                  trigger={['click']}
                  placement="top"
                  open={isChapterMenuOpen}
                  arrow={{ pointAtCenter: true }}
                  onOpenChange={setIsChapterMenuOpen}
                  overlayClassName="interactive-player-menu chapter-menu"
                  menu={{ items: chapterMenuItems, onClick: chapterMenuItemsHandler }}
                  getPopupContainer={() => document.getElementById('menu-container')}
                >
                  <Button shape="circle" type="text">
                    <Icon name="card" />
                  </Button>
                </Dropdown>
                <Button shape="circle" type="text">
                  <Icon
                    name={document.fullscreenElement ? 'normal_screen' : 'full_screen'}
                    onClick={handleFullScreen}
                  />
                </Button>
                <Dropdown
                  open={isMenuOpen}
                  placement="topRight"
                  trigger={['click']}
                  arrow={{ pointAtCenter: true }}
                  overlayClassName="interactive-player-menu three-dot-menu"
                  menu={{ items: menuItems, onClick: menuItemsHandler }}
                  getPopupContainer={() => document.getElementById('menu-container')}
                  onOpenChange={onOpenChangeMenu}
                >
                  <Button shape="circle" type="text">
                    <Icon name="menu_dots_vertical" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <Button
          shape="circle"
          type="text"
          id="controls-collapse-button"
          className="controls-collapse-button"
          data-state={`controls-${controlsVisible}`}
          onClick={handleControlsVisibility}
        >
          <Icon name={`${controlsVisible !== 'hidden' ? 'down_chevron' : 'up_chevron'}`} />
        </Button>
      </div>
    </div>
  )
}

export default Player
